import React, { useEffect, useState } from 'react';

import { NextPage } from 'next';
import { useRouter } from 'next/router';

import { useAppSelector } from '@/app/hooks';
import { selectUserDetails } from '@/features/user/userSlice';
import { getCookie } from '@/lib/auth-cookies-new';
import { AuthToken } from '@/lib/auth-token';

const withAuth = (Page: NextPage) => {
  const AuthenticatedComponent = () => {
    const router = useRouter();
    const profileData = useAppSelector((state) => selectUserDetails(state));
    const [settingUp, setSettingUp] = useState<boolean>(false);
    const [isStudentLogin, setIsStudentLogin] = useState<boolean>(false);

    useEffect(() => {
      const token = getCookie(null, `token`);
      const auth = new AuthToken(token);

      if (auth.isExpired) {
        router.replace(`/login`);
      } else if (
        profileData &&
        router.route === `/manage-student-registration-form` &&
        !profileData?.permissions?.includes(`ADMIN`)
      ) {
        router.replace(`/`);
      } else if (
        profileData?.permissions?.includes(`STUDENT`) &&
        !router?.route?.startsWith(`/test-prep/mock-test`)
      ) {
        setIsStudentLogin(true);
      }
      setSettingUp(false);
    }, [profileData]);

    if (isStudentLogin) {
      router.replace(`/test-prep/mock-test`);
      return null;
    }

    return !settingUp && !isStudentLogin ? <Page /> : null;
  };
  AuthenticatedComponent.displayName = Page.displayName || `NA`;
  return AuthenticatedComponent;
};

export default withAuth;
